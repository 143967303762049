import {Fancybox} from "@fancyapps/ui/src/Fancybox/Fancybox.js"
import cs from "@fancyapps/ui/src/Fancybox/l10n/cs.js"
import {SwiperElement} from "js/swiper/swiper"

const initProductGalleryFancybox = () => {
  const galleries = document.querySelectorAll<SwiperElement>(".product-slider")

  galleries.forEach((gallery) => {
    const galleryId = gallery.querySelector("a.i-slide")?.getAttribute("data-fancybox")

    const fancyboxSettings = {
      id: galleryId,
      l10n: cs,
      groupAll: true,
      Image: {
        zoom: false,
      },
      Toolbar: {
        display: ["close"],
      },
      on: {
        shouldClose: function () {
          const activeItem = document.querySelector(".is-nav-selected")
          const attribute = Number(activeItem?.getAttribute("data-index"))
          gallery.swiper?.slideTo(attribute, 0)
        },
      },
    }

    Fancybox.bind(`a.i-slide[data-fancybox=${galleryId}]`, fancyboxSettings)
  })
}

initProductGalleryFancybox()
