export {}

const alertsInit = () => {
  const alertsGroups = document.querySelectorAll(".g-alerts")

  alertsGroups.forEach((group) => {
    let timeout = 100
    const alerts = group.querySelectorAll(".i-alert")

    alerts.forEach((alert) => {
      setTimeout(() => {
        alert.classList.add("--show")
      }, timeout)
      timeout += 100
    })
  })

  const alerts = document.querySelectorAll(".i-alert")

  alerts.forEach((alert) => {
    const closeButtons = alert.querySelectorAll(".i-alert__close")

    setTimeout(() => {
      alert.classList.remove("--show")

      setTimeout(() => {
        alert.remove()
      }, 300)
    }, 5000)

    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        alert.classList.remove("--show")

        setTimeout(() => {
          alert.remove()
        }, 300)
      })
    })
  })
}

alertsInit()
document.addEventListener("alertsReinit", alertsInit)
