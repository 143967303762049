import Swiper from "swiper"
import {SwiperOptions} from "swiper/types"
import {EffectCreative, Keyboard, Pagination, Navigation} from "swiper/modules"

const productsSliders = document.querySelectorAll<HTMLElement>(".g-products-slider")

productsSliders.forEach((slider) => {
  const swiperSettings: SwiperOptions = {
    modules: [Keyboard, EffectCreative, Pagination, Navigation],
    spaceBetween: 32,
    watchOverflow: true,
    watchSlidesProgress: true,
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: slider.closest(".s-products-slider")?.querySelector<HTMLElement>(".g-products-slider__button.--next"),
      prevEl: slider.closest(".s-products-slider")?.querySelector<HTMLElement>(".g-products-slider__button.--prev"),
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      }
    },
    on: {
      init: () => {
        slider.classList.remove("--loading")
      },
    },
  }

  new Swiper(slider, swiperSettings)
})
