import Swiper from "swiper"
import {SwiperOptions} from "swiper/types"
import {EffectCreative, Keyboard, Pagination, Navigation} from "swiper/modules"

const heroSliders = document.querySelectorAll<HTMLElement>(".i-new-products")

heroSliders.forEach((item) => {
  const slider = item.querySelector<HTMLElement>(".i-new-products__slider")

  if (!slider) return

  const mediaQuery = window.matchMedia("(max-width: 575px)")

  const swiperSettings: SwiperOptions = {
    modules: [Keyboard, EffectCreative, Pagination, Navigation],
    slidesPerView: 2,
    spaceBetween: 8,
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: item.querySelector<HTMLElement>(".i-new-products__slider-button.--next"),
      prevEl: item.querySelector<HTMLElement>(".i-new-products__slider-button.--prev"),
    },
    on: {
      init: () => {
        slider.classList.remove("--loading")
      },
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 14,
      },
      1500: {
        slidesPerView: 5,
        spaceBetween: 14,
      },
    },
  }

  let swiperInstance: Swiper | null

  const handleSwiperOnMobile = () => {
    if (mediaQuery.matches) {
      swiperInstance?.destroy(true, true)
      slider.classList.remove("--loading")
    } else {
      swiperInstance = new Swiper(slider, swiperSettings)
    }
  }

  handleSwiperOnMobile()
  mediaQuery.addEventListener("change", handleSwiperOnMobile)
})
