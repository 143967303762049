import Headroom from "headroom.js"

const mainHeader = document.querySelector<HTMLElement>(".m-header")
const mainNav = document.querySelector(".m-nav")
const headerMiddle = document.querySelector(".m-header-middle")
const cartButton = document.querySelector(".m-header .i-cart-button")
const userButton = document.querySelector(".m-header .i-user-button")
const searchField = document.querySelector<HTMLInputElement>(".m-header .f-search input")
const closers = document.querySelectorAll(".--js-main-nav-closer")

export const mobileQuery = window.matchMedia("(max-width: 61.999em)")

const headRoomHeaderOptions: Headroom.HeadroomOptions = {
  offset: mainHeader?.clientHeight ?? 0,
}

const mainNavHeight = () => {
  const navHeight = mainNav?.clientHeight ?? 0
  mainHeader?.style.setProperty("--header-bottom-offset", `${navHeight}px`)
}

const middleNavHeight = () => {
  let headerMiddleHeight = headerMiddle?.clientHeight ?? 0
  headerMiddleHeight += 40
  document.querySelector<HTMLElement>("body")?.style.setProperty("--scroll-margin-top", `${headerMiddleHeight}px`)
}

export const headRoomHeader = mainHeader ? new Headroom(mainHeader, headRoomHeaderOptions) : null

if (headRoomHeader) headRoomHeader.init()

closers.forEach((closer) => {
  closer?.addEventListener("click", (e) => {
    if (!mobileQuery.matches) return
    e.preventDefault()
    mainHeader?.classList.remove("--user-open")
    mainHeader?.classList.remove("--cart-open")
    mainHeader?.classList.remove("--menu-open")
    mainHeader?.classList.remove("--search-open")
    document.body.classList.remove("--search-open")
    userButton?.classList.remove("--open")
    cartButton?.classList.remove("--open")
    if (searchField) searchField.form?.dispatchEvent(new Event("close"))
  })
})

cartButton?.addEventListener("click", (e) => {
  if (headerMiddle?.classList.contains("--layout-simple")) return
  if (!mobileQuery.matches) return

  if (e.target instanceof HTMLElement && e.target?.closest(".i-cart-dropdown")) return

  e.preventDefault()
  mainHeader?.classList.add("--cart-open")
  cartButton?.classList.add("--open")
})

userButton?.addEventListener("click", (e) => {
  if (!mobileQuery.matches) return
  if (!userButton.classList.contains("--filled")) return
  e.preventDefault()
  mainHeader?.classList.add("--user-open")
  mainHeader?.classList.remove("--menu-open")
  mainNav?.classList.remove("--open")
  userButton?.classList.add("--open")
})

searchField?.addEventListener("focus", () => {
  mainHeader?.classList.add("--search-open")
  document.body.classList.add("--search-open")
})

document.addEventListener("mousedown", (e) => {
  if (e.target instanceof Element && !e.target.closest(".m-header")) {
    mainHeader?.classList.remove("--search-open")
    document.body.classList.remove("--search-open")
  }
})

if (mainNav) {
  const resizeObserver = new ResizeObserver(mainNavHeight)
  resizeObserver.observe(mainNav)
}
if (headerMiddle) {
  const resizeObserver = new ResizeObserver(middleNavHeight)
  resizeObserver.observe(headerMiddle)
}
