export {}

const categoryItems = document.querySelectorAll<HTMLElement>(".i-submenu__category-items")
categoryItems.forEach((item) => {
  const showMore = item.querySelector<HTMLElement>(".i-submenu__show-more")

  showMore?.addEventListener("click", (e) => {
    e.preventDefault()
    const showMoreLimit = parseInt(showMore.dataset.showMoreLimit ?? "4")
    const hiddenItems = item.querySelectorAll(".i-submenu__category-item.--hidden")

    if (hiddenItems.length <= showMoreLimit) showMore.classList.add("--hidden")

    for (let i = 0; i < showMoreLimit; i++) {
      hiddenItems[i]?.classList.remove("--hidden")
    }
  })
})
