import Swiper from "swiper"
import {SwiperOptions} from "swiper/types"
import {Keyboard, Navigation, Controller, Thumbs} from "swiper/modules"

export {}

const galleries = document.querySelectorAll<HTMLElement>(".product-gallery")

galleries.forEach((gallery) => {
  const thumbnails = gallery.querySelector<HTMLElement>(".product-thumbnails")
  const slider = gallery.querySelector<HTMLElement>(".product-slider")

  if (!slider || !thumbnails) {
    gallery.classList.remove("--loading")
    return
  }

  const prevButton = thumbnails.querySelector<HTMLElement>(".swiper-button-prev")
  const nextButton = thumbnails.querySelector<HTMLElement>(".swiper-button-next")

  const thumbnailSettings: SwiperOptions = {
    modules: [Keyboard, Controller, Thumbs],
    spaceBetween: 8,
    keyboard: {
      enabled: true,
    },
    slidesPerView: "auto",
    slideToClickedSlide: true,
    loop: false,
    watchSlidesProgress: true,
    allowTouchMove: false,
    on: {
      slideChange: () => {
        window.lazyloading?.update()
      },
    },
    breakpoints: {
      0: {
        direction: "horizontal",
      },
      767: {
        direction: "vertical",
      },
    },
  }

  const thumbnailSwiper = new Swiper(thumbnails, thumbnailSettings)

  const slidersSettings: SwiperOptions = {
    modules: [Keyboard, Navigation, Thumbs],
    slidesPerView: 1,
    spaceBetween: 0,
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: nextButton,
      prevEl: prevButton,
    },
    loop: false,
    on: {
      init: () => {
        gallery.classList.remove("--loading")
      },
    },
    thumbs: {
      swiper: thumbnailSwiper,
    },
  }

  const heroSwiper = new Swiper(slider, slidersSettings)

  heroSwiper.on("slideChange", () => {
    prevButton?.classList.remove("--hidden")
  })
})
