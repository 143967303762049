import {SwiperElement} from "js/swiper/swiper"

export {}

const heroSliders = document.querySelectorAll<HTMLElement>(".i-new-products")

heroSliders.forEach((item) => {
  const button = item.querySelector<HTMLAnchorElement>(".i-new-products__button")
  const tabControl = item.querySelector<HTMLElement>(".i-new-products__tab-controls")
  const products = item.querySelectorAll<HTMLElement>(".i-product")
  const slider = item.querySelector<SwiperElement>(".i-new-products__slider")

  if (!tabControl) return

  tabControl.addEventListener("selected-tab", (event) => {
    const selectedTab = event.target

    if (!(selectedTab instanceof HTMLElement)) return

    if (button) {
      const url = selectedTab.dataset.url
      const title = selectedTab.dataset.title

      if (!url || !title) {
        button.classList.add("--hidden")
        return
      }

      button.classList.remove("--hidden")
      button.href = url
      const titleWrapper = button.querySelector<HTMLElement>(".btn__title")
      if (titleWrapper) titleWrapper.innerHTML = title
    }

    products.forEach((product) => {
      const slide = product.closest(".swiper-slide")
      slide?.classList.toggle("--hidden", !(selectedTab.dataset.id && product.dataset.groups?.includes(selectedTab.dataset.id)))
    })

    slider?.swiper?.update()
  })
})
