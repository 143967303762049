import Swiper from "swiper"
import {SwiperOptions} from "swiper/types"
import {Keyboard, Pagination, Navigation} from "swiper/modules"

const heroSliders = document.querySelectorAll<HTMLElement>(".g-testimonials.--slider")

heroSliders.forEach((slider) => {
  const swiperSettings: SwiperOptions = {
    modules: [Keyboard, Pagination, Navigation],
    slidesPerView: "auto",
    spaceBetween: 32,
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: slider.closest(".s-testimonials")?.querySelector<HTMLElement>(".g-testimonials__button.--next"),
      prevEl: slider.closest(".s-testimonials")?.querySelector<HTMLElement>(".g-testimonials__button.--prev"),
    },
    pagination: {
      el: slider.querySelector<HTMLElement>(".g-testimonials__pagination"),
      clickable: true,
    },
    on: {
      init: () => {
        slider.classList.remove("--loading")
      },
    },
  }

  new Swiper(slider, swiperSettings)
})
