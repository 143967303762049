import {makeRequest} from "js/request-api/request-api"

type WishlistData = {
  count?: number
}

const wishlistButtonWrapper = document.querySelector(".i-wishlist-button")

document.addEventListener("click", async (event) => {
  if (!(event.target instanceof Element)) return
  const {target} = event
  const button = target.closest<HTMLElement>(".i-add-wishlist-button:not(.--login)")

  if (!button) return

  const url = button.dataset.url

  if (!url) return

  button.classList.add("--loading")

  const response = await makeRequest(url, {
    method: "GET",
  })

  if (response.ok) {
    button.classList.toggle("--wishlisted")

    if (wishlistButtonWrapper) {
      const json = (await response.json()) as WishlistData

      if (json.count !== undefined) {
        const button = wishlistButtonWrapper.querySelector(".i-wishlist-button__button.--full")
        button?.setAttribute("data-indicator", json.count.toString())
        button?.classList.toggle("--indicator", json.count > 0)
        wishlistButtonWrapper.classList.toggle("--filled", json.count > 0)
      }
    }
  }
  button.classList.remove("--loading")
})
