// Swiper styles
import "swiper/swiper.scss"
import "swiper/modules/pagination.scss"
import "swiper/modules/effect-creative.scss"

// Types
import {Swiper} from "swiper/types"

// Sliders

import "@components/groups/testimonials-group/testimonials-group--slider"
import "@components/groups/products-slider-group/products-slider-group"
import "@components/product-detail/product-gallery/product-gallery"
import "@components/product-detail/product-gallery/product-gallery--fancybox"
import "@components/items/new-products-item/new-products-item--slider"

/**
 * If you need do something with the swiper instance, you can use the following interface
 * @example import {SwiperElement} from "js/swiper/swiper"
 *
 * const swiperElement = document.querySelector<SwiperElement>(".your-swiper")
 *
 * swiperElement.swiper
 */
export interface SwiperElement extends HTMLElement {
  swiper?: Swiper
}
