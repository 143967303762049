import {mobileQuery} from "@components/main-header/main-header"
import {debounce} from "js/debounce/debounce"
import {getSnippets} from "js/snippets/snippets"
import Typed from "typed.js"

const searchFormsSelector = ".f-search"
const searchForms = document.querySelectorAll(searchFormsSelector)

searchForms.forEach((form) => {
  const input = form.querySelector<HTMLInputElement>(".f-text input")
  const searchWrapper = form.querySelector(".f-search__dropdown-wrapper")
  const typingElement = form.querySelector(".f-search__search-typing > span")

  if (!input) return

  const baseUrl = input.dataset.searchUrl

  const getUrl = (baseUrl: string, phrase: string) => {
    return `${baseUrl}?phrase=${phrase}`
  }

  const inputTypingStrings = input.dataset.placeholder ?? "Michael Jackson"

  new Typed(typingElement, {
    strings: [inputTypingStrings],
    typeSpeed: 50,
    backSpeed: 40,
    backDelay: 500,
    startDelay: 1000,
    cursorChar: "|",
    loop: true,
  })

  if (input.value === "") {
    form.classList.remove("--disable-typing")
  }

  form.addEventListener("close", () => {
    if (input.value === "") {
      form.classList.remove("--disable-typing")
    }
    form.classList.remove("--open")
    document.body.classList.remove("--disable-scroll")
  })

  input.addEventListener("focus", () => {
    form.classList.add("--open")
    form.classList.add("--disable-typing")

    if (input.closest(".main-header")) document.body.classList.add("--disable-scroll")

    // Wierd ios bug, when input is focused, it scrolls down and search is not visible. This is a workaround, with minimal delay and scroll
    mobileQuery.matches && setTimeout(() => window.scrollTo(0, -1), 50)
  })

  const handleSearch = async () => {
    if (baseUrl === undefined || baseUrl === "") return

    const text = input.value

    if (text.length == 0) {
      searchWrapper?.classList.add("--loading")
      await getSnippets(getUrl(baseUrl, text))
      searchWrapper?.classList.remove("--loading")
    }

    if (text.length > 2) {
      form.classList.add("--open")
      searchWrapper?.classList.add("--loading")
      await getSnippets(getUrl(baseUrl, text))
      searchWrapper?.classList.remove("--loading")
    }
  }

  input?.addEventListener("input", debounce(handleSearch, 500))
})

if (searchForms) {
  document.addEventListener("mousedown", (e) => {
    if (e.target instanceof HTMLElement && !e.target?.closest(searchFormsSelector)) {
      searchForms.forEach((form) => {
        form.dispatchEvent(new Event("close"))
      })
    }
  })
}
